import React from 'react';

import Layout from 'components/layout';
import Seo from 'components/seo';
import Error from 'sections/error'

const NotFoundPage = () =>{

  return (
    <Layout>
      <Seo title="404: Not found" />
      <Error error={404} />
    </Layout>
  );
}

export default NotFoundPage;