/** @jsx jsx */
import { jsx, Box, Grid, Container, Heading, Text } from 'theme-ui';

const Error = (props) => {

  // Get our error code if one was provided
  const { error } = props;

  let errorCode = {
    title: "",
    message: ""
  }

  if(!error) {
    errorCode.title = "Hmm, we're not sure what happened.";
    errorCode.message = "Try refreshing the page, and if that doesn't work, try again in a couple minutes.";
  }

  if(error === 404) {
    errorCode.title = "We could't find that!";
    errorCode.message = "Thankfully for you, we came to rescue you! Click the 'Trelent' logo to go back to the front page.";
  }
  else if(error === 500) {
    errorCode.title = "We screwed something up.";
    errorCode.message = "Sorry about that! We'll have our team on it and get this fixed ASAP. In the meantime, you can try refreshing the page or trying again in a couple minutes.";
  }
  else {
    errorCode.title = "Hmm, we're not sure what happened.";
    errorCode.message = "Try refreshing the page, and if that doesn't work, try again in a couple minutes.";
  }

  return (
    <section id="home" sx={styles.section}>
      <Container sx={styles.container}>
        <Grid sx={styles.grid}>
          <Box sx={styles.content}>
            <Heading as="h1">
              {errorCode.title}
            </Heading>
            <Text as="p">
              {errorCode.message}
            </Text>
          </Box>
          <Box as="figure" sx={styles.illustration}>
            <img
              src={"../undraw_warning.svg"}
              sx={styles.illustration.image}
              alt={"Woman wearing a hardhat waving an organge flag with a caution sign next to her."}
            />
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Error;

const styles = {
  section: {
    pt: [8, null, null, null, 10, 0],
    pb: [8, null, null, null, 10, 0],
  },
  container: {
    px: [3, null, null, 6],
  },
  grid: {
    alignItems: ['center'],
    gridTemplateColumns: ['1fr', null, null, null, '1fr 1fr'],
    minHeight: [null, null, null, null, '45vh', '80vh'],
    pt: [15, null, null, 17, 12],
  },
  content: {
    textAlign: ['center', null, null, null, 'left'],
    h1: {
      color: 'text',
      fontWeight: 'bold',
      fontSize: [8, null, null, null, 12, 14, 15],
      lineHeight: [1.1, null, null, null, 1.2],
      letterSpacing: 'heading',
    },
    p: {
      color: 'textSecondary',
      fontSize: [2, null, null, 4],
      lineHeight: 1.5,
      maxWidth: 480,
      mx: [null, null, null, 'auto', 'unset'],
      marginTop: [1, null, null, 3],
    },
  },
  newsUpdate: {
    backgroundColor: 'muted',
    boxShadow: '0px 2px 8px rgba(67, 99, 136, 0.07)',
    borderRadius: 50,
    cursor: 'pointer',
    padding: '4px 15px 4px 5px',
    display: 'inline-flex',
    alignItems: 'center',
    color: 'textSecondary',
    fontSize: ['12px', null, null, 1],
    mb: 4,
    textAlign: 'left',
    span: {
      backgroundColor: 'primary',
      color: 'white',
      fontSize: ['10px', null, null, '13px'],
      fontWeight: 700,
      display: 'inline-flex',
      minHeight: 24,
      alignItems: 'center',
      px: '11px',
      borderRadius: 50,
      mr: '12px',
    },
  },
  buttonGroup: {
    mt: [6, null, null, null, 10],
    button: {
      minHeight: 45,
      px: ['17px', 4],
    },
    '.white': {
      boxShadow: '0px 4px 6px rgba(125, 128, 170, 0.08)',
      borderRadius: 5,
      fontWeight: 500,
      ml: 3,
    },
  },
  illustration: {
    maxWidth: [null, null, null, '80%', '100%'],
    mx: [null, null, null, 'auto', 'unset'],
    mt: [6, null, null, null, 0],
    image: {
      width: ['100%']
    }
  },
};
